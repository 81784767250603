<template>
  <div class="animated fadeIn">
    <ResponseAlert ref="response" />
    <b-row class="mt-2">
      <b-col
        md="9"
      >
        <h4>
          Detail Info
        </h4>
        <b-row
          class="my-1"
          align-v="center"
        >
          <b-col sm="3">
            <label
              class="h5"
              for="name"
            >Tahun Bergabung</label>
          </b-col>
          <b-col sm="8">
            <b-form-datepicker
              v-model="models.joinedDate"
              disabled
            />
          </b-col>
        </b-row>
        <b-row
          class="my-1"
          align-v="baseline"
        >
          <b-col sm="3">
            <label
              class="h5"
              for="phone_number"
            >No Telepon</label>
          </b-col>
          <b-col sm="8">
            <b-form-input
              id="phone_number"
              v-model="models.phone"
              type="tel"
              disabled
            />
          </b-col>
        </b-row>

        <b-row
          class="my-1"
          align-v="center"
        >
          <b-col sm="3">
            <label
              class="h5"
              for="name"
            >Alamat KFS</label>
          </b-col>
          <b-col sm="8">
            <b-form-textarea
              v-model="models.address"
              rows="4"
              disabled
            />
          </b-col>
        </b-row>

      </b-col>
    </b-row>

    <h4 class="mt-2">
      Akun Bank
    </h4>
    <div class="table-responsive">
      <table class="table b-table">
        <thead>
          <tr>
            <th
              v-for="(row, key) in config.headers"
              :key="key"
              :width="row.width"
              :class="row.class"
            >
              <a
                :id="row.value"
                class="sort dark-font"
                href="javascript:void(0)"
              >
                {{ row.title }}
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="models.banks.length">
            <tr
              v-for="(row, key) in models.banks"
              :key="key"
            >
              <td>{{ row.bank.name }}</td>
              <td>{{ row.accountNumber }}</td>
              <td>{{ row.accountHolder }}</td>
            </tr>
          </template>
          <tr v-else>
            <td
              :colspan="3"
              align="center"
            >
              No data available.
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h4 class="mt-3">
      Setting Jam Operasional KFS
    </h4>
    <b-button
      v-if="availableDays.length > 1"
      variant="primary"
      class="btn-min-width rounded my-1 full-width-responsive"
      @click.prevent="openEmployeeModal('Tambah')"
    >
      Tambah Hari
    </b-button>
    <div class="table-responsive">
      <table class="table b-table">
        <thead>
          <tr>
            <th
              v-for="(row, key) in configOH.headers"
              :key="key"
              :width="row.width"
              :class="row.class"
            >
              <a
                :id="row.value"
                class="sort dark-font"
                href="javascript:void(0)"
              >
                {{ row.title }}
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="configOH.rows.length">
            <tr
              v-for="(row, key) in configOH.rows"
              :key="key"
            >
              <td class="text-capitalize">
                {{ row.dayName }}
              </td>
              <td>{{ row.startFrom }} - {{ row.endFrom }}</td>
              <td
                class="d-flex"
              >
                <button
                  type="button"
                  title="Edit"
                  class="btn-action bg-transparent border-0 text-primary"
                  @click="openEmployeeModal(row)"
                >
                  <feather-icon icon="EditIcon" />
                </button>
                <button
                  type="button"
                  title="Delete"
                  class="btn-action bg-transparent border-0 text-primary"
                  @click="destroy(row.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </button>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td
              :colspan="3"
              align="center"
            >
              No data available.
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal Set Operational Hours -->
    <b-modal
      id="modal-form-oh"
      ref="modal-form-oh"
      centered
      hide-footer
    >
      <form
        @submit.prevent="saveOH"
      >
        <div class="animated fadeIn">
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="4">
              <label
                class="h5"
                for="stock"
              >Pilih hari</label>
            </b-col>
            <b-col sm="8">
              <b-form-select
                id="days"
                v-model="data.day"
                :options="availableDays"
                required
                class="w-100"
              />
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="4">
              <label
                class="h5"
                for="stock"
              >Jam Operasional</label>
            </b-col>
            <b-col sm="8">
              <b-row>
                <b-col sm="6">
                  <b-form-input
                    v-model="data.startFrom"
                    required
                    type="time"
                  />
                </b-col>
                <b-col sm="6">
                  <b-form-input
                    v-model="data.endFrom"
                    required
                    type="time"
                    :min="data.startFrom"
                    :disabled="!data.startFrom"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-md-end justify-content-around">

            <b-button
              type="button"
              variant="outline-secondary"
              class="btn-min-width rounded"
              @click="$bvModal.hide('modal-form-oh')"
            >
              Batal
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              class="btn-min-width rounded ml-md-1 ml-0"
            >
              Simpan
            </b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>

</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow, BCol, BFormInput, BFormDatepicker, BFormTextarea, BButton, BFormSelect,
} from 'bootstrap-vue'
import api from '@/utils/api'

export default {
  name: 'ListKFSdetail',
  components: {
    BFormSelect,
    BRow,
    BCol,
    BFormTextarea,
    BFormDatepicker,
    BFormInput,
    BButton,
  },
  props: ['models'],
  data() {
    return {
      config: {
        headers: [{
          title: 'Bank',
          value: '',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Nomor Rekening',
          value: '',
          align: 'text-left',
          width: '40%',
        }, {
          title: 'Nama',
          value: '',
          align: 'text-left',
          width: '40%',
        }],
      },
      configOH: {
        uri: this.$route.meta.link,
        api: api.kfsHours,
        rows: [],
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Hari',
          value: '',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Jam Operasional',
          value: '',
          align: 'text-left',
          width: '60%',
        }, {
          title: 'Aksi',
          value: '',
          align: 'text-left',
          width: '20%',
        }],
      },
      masters: {
        days: [
          {
            text: '-- Pilih hari --',
            value: null,
          },
          {
            text: 'Senin',
            value: 1,
          },
          {
            text: 'Selasa',
            value: 2,
          },
          {
            text: 'Rabu',
            value: 3,
          },
          {
            text: 'Kamis',
            value: 4,
          },
          {
            text: 'Jum\'at',
            value: 5,
          },
          {
            text: 'Sabtu',
            value: 6,
          },
          {
            text: 'Minggu',
            value: 7,
          },
        ],
      },
      data: {
        kfsId: this.$route.params.id,
        day: null,
        startFrom: null,
        endFrom: null,
      },
      isAddForm: false,
    }
  },
  computed: {
    availableDays() {
      return this.isAddForm ? this.masters.days.filter(day => !this.configOH.rows.map(confDay => confDay.day).includes(day.value)) : this.masters.days
    },
  },
  created() {
    const _ = this
    _.get()
  },
  methods: {
    get() {
      this.$axios.get(api.kfsHours, {
        params: {
          kfsId: this.$route.params.id,
        },
      }).then(res => {
        this.configOH.rows = res.data.data.rows.sort((a, b) => a.day - b.day)
      })
    },
    destroy(id) {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`${api.kfsHours}/${id}`)
            .then(res => {
              this.$refs.response.setResponse(res.data.message, 'success')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },
    openEmployeeModal(state) {
      this.$bvModal.show('modal-form-oh')
      this.isAddForm = typeof state === 'string'
      this.data = {
        id: this.isAddForm ? null : state.id,
        kfsId: this.$route.params.id,
        day: this.isAddForm ? null : state.day,
        startFrom: this.isAddForm ? null : state.startFrom,
        endFrom: this.isAddForm ? null : state.endFrom,
      }
    },
    saveOH() {
      let request = ''
      let message = 'create'

      if (!this.data.id) {
        delete this.data.id
        request = this.$axios.post(api.kfsHours, this.data)
      } else {
        const { id } = this.data
        delete this.data.id
        message = 'update'
        request = this.$axios.put(`${api.kfsHours}/${id}`, this.data)
      }
      request
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Success ${message} Operational Hours`,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('modal-form-oh')
          this.get()
        })
        .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
    },
  },
}
</script>

<style>

</style>
