<template>
  <div class="animated fadeIn">
    <b-row>
      <ResponseAlert ref="response" />
      <div class="col-sm-12 mb-2 mb-lg-2 d-flex justify-content-between align-items-center flex-column flex-lg-row">
        <div class="col-lg-4 px-0 order-1 order-md-0 mt-2 mb-0 mb-md-2 mt-lg-0 mb-lg-0">
          <div class="input-group">
            <input
              v-model="config.search"
              type="text"
              placeholder="Cari nama treatment atau kata kunci"
              class="form-control form-control-sm"
              @keyup.enter="config.page = 1; get()"
            >
            <div class="input-group-append">
              <div
                class="input-group-text"
                @click="config.page = 1; get()"
              >
                <feather-icon icon="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div class="order-0 order-md-1 mb-0 text-center">
          <b-button
            variant="secondary"
            class="btn-min-width rounded full-width-responsive"
            @click="$bvModal.show('modal-import-customer')"
          >
            <feather-icon
              icon="UploadCloudIcon"
            />
            Import Data
          </b-button>
          <b-button
            variant="secondary"
            class="btn-min-width rounded full-width-responsive mx-md-1 my-1 my-md-0"
            @click="exportExcel"
          >
            <feather-icon
              icon="DownloadIcon"
            />
            Export Data
          </b-button>
          <b-button
            variant="outline-danger"
            class="btn-min-width rounded full-width-responsive"
            @click="directToDetail"
          >
            Tambah Customer
          </b-button>
        </div>
      </div>
      <b-col sm="12">
        <div class="table-responsive">
          <table class="table b-table">
            <thead>
              <tr>
                <th
                  v-for="(row, key) in config.headers"
                  :key="key"
                  :width="row.width"
                  :class="row.align"
                >
                  <a
                    v-if="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                    @click="sort(row.value)"
                  >
                    {{ row.title }}
                    <i
                      :id="row.value"
                      class="fa fa-sort"
                    />
                  </a>
                  <a
                    v-else
                    :id="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                  >
                    {{ row.title }}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="config.total_data">
                <tr
                  v-for="(row, key) in config.rows"
                  :key="key"
                >
                  <td>{{ row.profile.fullName }}</td>
                  <td>{{ row.profile.phone }}</td>
                  <td>{{ row.email }}</td>
                  <td>{{ row.uniqueId }}</td>
                  <td>{{ row.profile.createdAt ? moment(row.profile.createdAt).format('DD/MM/YYYY') : '-' }}</td>
                  <td>{{ row.lastVisited ? moment(row.lastVisited).format('DD/MM/YYYY') : '-' }}</td>
                  <td>{{ row.profile.since }}</td>
                  <td align="center">
                    <div class="d-flex">
                      <custom-button
                        type="button"
                        permission="detail/customer"
                        title="Detail"
                        class-name="btn-action bg-transparent border-0 text-primary p-0"
                        @click="directToDetail('detail', row.id)"
                      >
                        <feather-icon icon="EyeIcon" />
                      </custom-button>
                      <custom-button
                        type="button"
                        permission="edit/customer"
                        title="Edit"
                        class-name="btn-action bg-transparent border-0 text-primary px-1"
                        @click="directToDetail('edit', row.id)"
                      >
                        <feather-icon icon="EditIcon" />
                      </custom-button>
                      <custom-button
                        type="button"
                        permission="delete/customer"
                        title="Delete"
                        class-name="btn-action bg-transparent border-0 text-primary p-0"
                        @click="destroy(row.id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </custom-button>
                    </div>
                  </td>
                </tr>
              </template>
              <tr v-if="!config.total_data">
                <td
                  :colspan="config.headers.length"
                  align="center"
                >
                  No data available.
                </td>
              </tr>
            </tbody>
            <tfoot v-if="config.total_data">
              <tr>
                <td
                  :colspan="config.headers.length"
                  class="p-0"
                >
                  <div class="d-flex justify-content-between mt-2">
                    <b-form-select
                      v-model="config.per_page"
                      :options="perPageOptions"
                      class="w-auto"
                    />
                    <b-pagination
                      v-model="config.page"
                      :total-rows="config.total_data"
                      :per-page="config.per_page"
                      @change="gotoPage"
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </b-col>
    </b-row>

    <!-- Modal Import -->
    <b-modal
      id="modal-import-customer"
      ref="modal-import-customer"
      centered
      hide-footer
    >
      <div
        class="py-3 d-flex justify-content-center align-items-center"
        @dragover.prevent
        @drop.prevent="event => importExcel(event, 'drag')"
      >
        <label
          for="file-input"
          style="cursor: pointer;"
        >
          <input
            id="file-input"
            type="file"
            accept=".xlsx, .xls, .csv"
            hidden
            @change="event => importExcel(event)"
          >
          <div class="text-center">
            <feather-icon
              icon="UploadCloudIcon"
              size="100"
            />
          </div>
          <span class="d-block">
            Upload File (.xlsx)
          </span>
        </label>
      </div>
    </b-modal>
  </div>

</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric } from '@validations'
import {
  BRow, BCol, BPagination, BFormSelect, BButton, BModal, BFormInput,
} from 'bootstrap-vue'
import api from '@/utils/api'
import { exportExcel } from '@/utils/helpers'

export default {
  name: 'ListKFSTreatment',
  components: {
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: api.customers,
        rows: [],
        additional_params: {
          kfsId: this.$route.params.id,
        },
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Nama',
          value: 'profile.firstName',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'No. Telepon',
          value: 'profile.phone',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Email',
          value: 'email',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Member',
          value: 'uniqueId',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Tanggal Regis',
          value: 'profile.createdAt',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Kunjungan Terakhir',
          value: '',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Tags',
          value: 'profile.since',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Action',
          value: '',
          class: 'text-center',
          width: '10%',
        }],
      },
      models: {
        kfsId: this.$route.params.id,
        accuTreatmentId: [],
        price: null,
        durationHours: null,
        durationMinutes: null,
      },
      masters: {
        treatments: [],
      },
      vars: {
        form: {
          modalTitle: '',
        },
        unitPrice: '',
        accuTreatmentId: [],
      },
      required,
      numeric,
    }
  },
  computed: {
    modalFormTitle() {
      return this.vars.form.modalTitle
    },
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
  },
  watch: {
    perPage() {
      this.config.page = 1
      this.get()
    },
  },
  created() {
    const _ = this
    _.get()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    directToDetail(type, id) {
      if (type === 'detail') {
        this.$router.push(`/${this.config.uri}/${this.$route.params.id}/customer/${id}`)
      } else if (type === 'edit') {
        this.$router.push(`/${this.config.uri}/${this.$route.params.id}/customer/edit/${id}`)
      } else {
        this.$router.push(`/${this.config.uri}/${this.$route.params.id}/customer/add`)
      }
    },
    destroy(id) {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`${api.customers}/${id}`)
            .then(res => {
              this.$refs.response.setResponse(res.data.message, 'success')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },

    exportExcel() {
      exportExcel('List Customers KFS', `${api.customers}/export?kfsId=${this.$route.params.id}`)
    },
    // eslint-disable-next-line consistent-return
    importExcel(event, type) {
      const fileSizeLimit = 1024 * 1024 * 12 // limit 12 MB

      const filesLength = type === 'drag' ? event.dataTransfer.files.length : event.target.files[0].length
      const file = type === 'drag' ? event.dataTransfer.files[0] : event.target.files[0]
      if (filesLength > 1) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Only accept single file',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type !== 'application/vnd.ms-excel' && file.type !== 'text/csv') {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Format file not supported',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
      if (file.size < fileSizeLimit) {
        const form = new FormData()
        form.append('file', file)
        form.append('kfsId', this.$route.params.id)
        this.$axios.post(`${api.customers}/import`, form)
          .then(res => {
            if (res.data.data.length) {
              const errors = res.data.data.filter(error => error.message !== 'Validation error')
              if (errors.length < 5) {
                errors.forEach(error => {
                  const user = error.data[0].toUpperCase()
                  const message = error.message.toUpperCase()
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Failed',
                      text: `(${user}) -> ${message}`,
                      icon: 'XIcon',
                      variant: 'danger',
                    },
                  })
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Failed',
                    text: 'Some users already exist, please re-check the file.',
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success import data',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })

              this.get()
              this.$bvModal.hide('modal-import-customer')
            }
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed',
                text: 'Format column file not support, please check format guide.',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
        // eslint-disable-next-line no-param-reassign
        event.target.value = null
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Max file size is 2 MB',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
  },

}
</script>
