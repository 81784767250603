<template>
  <div class="animated fadeIn">
    <b-row>
      <ResponseAlert ref="response" />
      <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
        <div class="col-md-4 col-12 px-0 order-1 order-md-0">
          <div class="input-group">
            <input
              v-model="config.search"
              type="text"
              placeholder="Cari nama karyawan atau kata kunci"
              class="form-control form-control-sm"
              @keyup.enter="config.page = 1; get()"
            >
            <div class="input-group-append">
              <div
                class="input-group-text"
                @click="config.page = 1; get()"
              >
                <feather-icon icon="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div class="order-0 order-md-1 mb-1 mb-md-0 text-center">
          <b-button
            v-if="hasPermission('export/employee/kfs-management')"
            variant="secondary"
            class="btn-min-width rounded full-width-responsive"
            @click="exportExcel"
          >
            <feather-icon
              icon="DownloadIcon"
            />
            Export Data
          </b-button>
          <b-button
            v-if="hasPermission('add/employee/kfs-management')"
            variant="outline-danger"
            class="btn-min-width rounded ml-md-1 mt-1 mt-md-0 full-width-responsive"
            @click="openEmployeeModal('Tambah')"
          >
            Tambah Karyawan
          </b-button>
        </div>
      </div>
      <b-col sm="12">
        <div class="table-responsive">
          <table class="table b-table">
            <thead>
              <tr>
                <th
                  v-for="(row, key) in config.headers"
                  :key="key"
                  :width="row.width"
                  :class="row.align"
                >
                  <a
                    v-if="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                    @click="sort(row.value)"
                  >
                    {{ row.title }}
                    <i
                      :id="row.value"
                      class="fa fa-sort"
                    />
                  </a>
                  <a
                    v-else
                    :id="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                  >
                    {{ row.title }}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="config.total_data">
                <tr
                  v-for="(row, key) in config.rows"
                  :key="key"
                >
                  <td>{{ row.profile.fullName }}</td>
                  <td>{{ row.email }}</td>
                  <td>{{ row.roleName }}</td>
                  <td>
                    <div class="d-flex justify-content-center">
                      <custom-button
                        type="button"
                        permission="edit/employee/kfs-management"
                        title="Edit"
                        class-name="btn-action bg-transparent border-0 text-primary p-0 pl-1"
                        @click="openEmployeeModal('Edit', row.id)"
                      >
                        <feather-icon icon="EditIcon" />

                      </custom-button>
                      <custom-button
                        type="button"
                        permission="delete/employee/kfs-management"
                        title="Delete"
                        class-name="btn-action bg-transparent border-0 text-primary p-0 pl-1"
                        @click="destroy(row.id)"
                      >
                        <feather-icon icon="TrashIcon" />

                      </custom-button>
                    </div>
                  </td>
                </tr>
              </template>
              <tr v-if="!config.total_data">
                <td
                  :colspan="config.headers.length"
                  align="center"
                >
                  No data available.
                </td>
              </tr>
            </tbody>
            <tfoot v-if="config.total_data">
              <tr>
                <td
                  :colspan="config.headers.length"
                  class="p-0"
                >
                  <div class="d-flex justify-content-between mt-2">
                    <b-form-select
                      v-model="config.per_page"
                      :options="perPageOptions"
                      class="w-auto"
                    />
                    <b-pagination
                      v-model="config.page"
                      :total-rows="config.total_data"
                      :per-page="config.per_page"
                      @change="gotoPage"
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </b-col>
    </b-row>

    <!-- Modal Form Karyawan -->
    <b-modal
      id="modal-form-karyawan"
      ref="modal-form-karyawan"
      centered
      :title="`${vars.form.modalTitle} Karyawan`"
      hide-footer
    >
      <ResponseAlert ref="responseForm" />

      <validation-observer ref="formKFSEmployee">
        <form
          @submit.prevent="save"
        >
          <b-row class="mt-2">
            <b-col
              sm="3"
              class="mb-2"
            >
              <div class="image-input">
                <validation-provider
                  #default="{ errors }"
                  name="Photo"
                  :rules="vars.rulesImage"
                >
                  <b-img
                    :src="getUrlOfFile(vars.photo)"
                    class="mb-2"
                  />
                  <label
                    for="file-input"
                  >
                    <input
                      id="file-input"
                      type="file"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      hidden
                      @change="AddPhoto"
                    >
                    <feather-icon icon="PlusIcon" />
                    Upload
                  </label>

                  <input
                    v-model="vars.photo"
                    type="hidden"
                    :state="errors.length > 0 ? false:null"
                  >
                  <small class="text-danger text-center d-block m-auto">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col
              md="9"
            >
              <b-row
                class="mb-1"
                align-v="baseline"
              >
                <b-col sm="5">
                  <label
                    class="h5"
                    for="name"
                  >Nama</label>
                </b-col>
                <b-col sm="7">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="models.name"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="5">
                  <label
                    class="h5"
                    for="bod"
                  >Tanggal Lahir</label>
                </b-col>
                <b-col sm="7">
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal lahir"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="bod"
                      v-model="models.bod"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      locale="en"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="5">
                  <label
                    class="h5"
                  >Jenis Kelamin</label>
                </b-col>
                <b-col sm="7">
                  <div class="d-flex justify-content-between">
                    <label
                      class="radio-inline"
                    >
                      <input
                        v-model="models.gender"
                        type="radio"
                        value="perempuan"
                        name="gender"
                      >
                      <span class="font-weight-bold text-capitalize">Perempuan</span>
                    </label>
                    <label
                      class="radio-inline"
                    >
                      <input
                        v-model="models.gender"
                        type="radio"
                        value="laki"
                        name="gender"
                      >
                      <span class="font-weight-bold text-capitalize">Laki-Laki</span>
                    </label>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="10"
            >
              <b-row
                class="mb-1"
                align-v="baseline"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="phone"
                  >No. Telepon</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="No. telepon"
                    rules="required|numeric|min:8"
                  >
                    <b-form-input
                      id="phone"
                      v-model="models.phone"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="address"
                  >Alamat</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Alamat"
                    rules="required"
                  >
                    <b-form-textarea
                      id="address"
                      v-model="models.address"
                      rows="3"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                  >Role</label>
                </b-col>
                <b-col sm="8">
                  <div class="d-flex justify-content-between">
                    <label
                      class="radio-inline"
                    >
                      <input
                        v-model="models.roleId"
                        type="radio"
                        :value="3"
                        name="role"
                      >
                      <span class="font-weight-bold text-capitalize">Kasir</span>
                    </label>
                    <label
                      class="radio-inline"
                    >
                      <input
                        v-model="models.roleId"
                        type="radio"
                        :value="4"
                        name="role"
                      >
                      <span class="font-weight-bold text-capitalize">Terapis</span>
                    </label>
                  </div>
                </b-col>
              </b-row>
              <b-row
                class="mb-1"
                align-v="baseline"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="email"
                  >Email</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="models.email"
                      type="email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                v-if="vars.form.modalTitle === 'Tambah'"
                class="mb-1"
                align-v="baseline"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="name"
                  >Password</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="models.password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        disabled
                        @keydown.space.prevent
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-md-end justify-content-around">

            <b-button
              type="button"
              variant="outline-secondary"
              class="btn-min-width rounded"
              @click="$bvModal.hide('modal-form-karyawan')"
            >
              Batal
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              class="btn-min-width rounded ml-md-1 ml-0"
            >
              Simpan
            </b-button>
          </div>
        </form>
      </validation-observer>

    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol, BPagination, BFormSelect, BButton, BModal, BFormDatepicker, BImg, BFormInput, BFormTextarea, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
// import table from '@/utils/table.js'
import api from '@/utils/api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import { getUrlOfFile, exportExcel, uploadFile } from '@/utils/helpers'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ListKFSKaryawan',
  components: {
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    BModal,
    BFormDatepicker,
    BImg,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: api.kfsEmployee,
        rows: [],
        additional_params: {
          kfsId: this.$route.params.id,
        },
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Nama',
          value: 'profile.firstName',
          align: 'text-left',
          width: '25%',
        }, {
          title: 'Email',
          value: 'email',
          align: 'text-left',
          width: '40%',
        }, {
          title: 'Role',
          value: 'roleId',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Aksi',
          value: '',
          align: 'text-center',
          width: '15%',
        }],
      },
      vars: {
        form: {
          modalTitle: '',
        },
        photo: '',
        rulesImage: 'required|image',
      },
      models: {},
      getUrlOfFile,
    }
  },
  computed: {
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imageField() {
      return this.vars.photo
    },

  },
  watch: {
    perPage() {
      this.config.page = 1
      this.get()
    },
    imageField() {
      if (typeof this.vars.photo !== 'string') {
        this.vars.rulesImage = 'required|image'
      }
    },
  },
  created() {
    const _ = this
    _.get()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    destroy(id) {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`${api.kfsEmployee}/${id}`)
            .then(res => {
              this.$refs.response.setResponse(res.data.message, 'success')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },
    AddPhoto(event) {
      const fileSizeLimit = 1024 * 1024 * 2 // limit 2 MB
      if (event.target.files[0].size < fileSizeLimit) {
        const photo = event.target.files[0]
        this.vars.photo = photo
        // eslint-disable-next-line no-param-reassign
        event.target.value = null
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Max file size is 2 MB',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    openEmployeeModal(state, id) {
      this.vars.form.modalTitle = state
      if (state === 'Tambah') {
        this.models = {
          kfsId: this.$route.params.id,
          roleId: 3,
          name: '',
          photo: '',
          gender: 'perempuan',
          bod: '',
          phone: '',
          email: '',
          address: '',
          password: 'p@ssword123',
        }
        this.$bvModal.show('modal-form-karyawan')
      } else {
        this.$axios.get(`${api.kfsEmployee}/${id}`)
          .then(res => {
            this.vars.rulesImage = 'required'
            const { data } = res.data
            this.models = {
              kfsId: this.$route.params.id,
              roleId: data.roleId,
              name: data.profile.fullName,
              photo: data.profile.photo,
              gender: data.profile.gender,
              bod: data.profile.bod,
              phone: data.profile.phone,
              email: data.email,
              address: data.profile.address,
              password: '',
            }
            this.vars.idUser = id
            this.vars.photo = data.profile.photo
            this.$bvModal.show('modal-form-karyawan')
          })
      }

      this.vars.photo = ''
    },
    save() {
      this.$refs.formKFSEmployee.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          // get file image url
          this.models.photo = typeof this.vars.photo === 'string' ? this.vars.photo : await uploadFile(this.vars.photo)

          if (this.vars.form.modalTitle === 'Tambah') {
            request = this.$axios.post(api.kfsEmployee, this.models)
          } else {
            message = 'update'
            request = this.$axios.put(`${api.kfsEmployee}/${this.vars.idUser}`, this.models)
          }
          request
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} KFS Employee`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('modal-form-karyawan')
              this.get()
            })
            .catch(error => this.$refs.responseForm.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
    exportExcel() {
      exportExcel('List KFS Karyawan', `${api.kfsEmployee}/export?kfsId=${this.$route.params.id}`)
    },
  },
}
</script>
