<template>
  <div class="animated fadeIn">
    <b-row>
      <ResponseAlert ref="response" />
      <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
        <div class="col-md-4 col-12 px-0 order-1 order-md-0">
          <div class="input-group">
            <input
              v-model="config.search"
              type="text"
              placeholder="Cari nama customer atau kata kunci"
              class="form-control form-control-sm"
              @keyup.enter="config.page = 1; get()"
            >
            <div class="input-group-append">
              <div
                class="input-group-text"
                @click="config.page = 1; get()"
              >
                <feather-icon icon="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div class="order-0 order-md-1 mb-1 mb-md-0 text-center full-width-responsive">
          <b-button
            v-if="hasPermission('export/poin/kfs-management')"
            variant="secondary"
            class="btn-min-width rounded full-width-responsive"
            @click="exportExcel"
          >
            <feather-icon
              icon="DownloadIcon"
            />
            Export Data
          </b-button>
          <b-button
            v-if="hasPermission('add/poin/kfs-management')"
            variant="outline-danger"
            class="btn-min-width rounded ml-md-1 mt-1 mt-md-0 full-width-responsive"
            @click="openEmployeeModal('Tambah')"
          >
            Redeem
          </b-button>
        </div>
      </div>
      <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
        <div class="col-md-6 col-12 px-0 order-1 order-md-0">
          <div
            class="input-group d-flex align-items-center"
          >
            <label
              v-if="vars.expiredPoin"
              class="text-center w-100 text-md-left"
            >Point berlaku sampai: {{ vars.expiredPoin }}</label>
            <b-button
              v-if="enableSetDate"
              type="submit"
              variant="outline-danger"
              class="btn-min-width rounded full-width-responsive"
              @click="$bvModal.show('modal-claim-poin')"
            >
              Setup Poin
            </b-button>
          </div>
        </div>
        <div class="order-0 order-md-1 mb-1 mb-md-0 text-center full-width-responsive">
          <p class="m-0">
            Total Point: {{ vars.totalPoint || 0 }} poin
          </p>
        </div>
      </div>
      <b-col sm="12">
        <div class="table-responsive">
          <table class="table b-table">
            <thead>
              <tr>
                <th
                  v-for="(row, key) in config.headers"
                  :key="key"
                  :width="row.width"
                  :class="row.class"
                >
                  <a
                    v-if="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                    @click="sort(row.value)"
                  >
                    {{ row.title }}
                    <i
                      :id="row.value"
                      class="fa fa-sort"
                    />
                  </a>
                  <a
                    v-else
                    :id="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                  >
                    {{ row.title }}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="config.total_data">
                <tr
                  v-for="(row, key) in config.rows"
                  :key="key"
                >
                  <td>{{ moment(row.date).format('DD-MM-YYYY') }}</td>
                  <td>{{ row.reward }}</td>
                  <td>{{ row.poin }}</td>
                  <td>
                    <custom-button
                      type="button"
                      permission="edit/poin/kfs-management"
                      title="Edit"
                      class-name="btn-action bg-transparent border-0 text-primary p-0 pl-1"
                      @click="openEmployeeModal('Edit', row.id)"
                    >
                      <feather-icon icon="EditIcon" />
                    </custom-button>
                    <custom-button
                      type="button"
                      title="Delete"
                      class-name="btn-action bg-transparent border-0 text-primary p-0 pl-1"
                      @click="destroy(row.id)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </custom-button>
                  </td>
                </tr>
              </template>
              <tr v-if="!config.total_data">
                <td
                  :colspan="config.headers.length"
                  align="center"
                >
                  No data available.
                </td>
              </tr>
            </tbody>
            <tfoot v-if="config.total_data">
              <tr>
                <td
                  :colspan="config.headers.length"
                  class="p-0"
                >
                  <div class="d-flex justify-content-between mt-2">
                    <b-form-select
                      v-model="config.per_page"
                      :options="perPageOptions"
                      class="w-auto"
                    />
                    <b-pagination
                      v-model="config.page"
                      :total-rows="config.total_data"
                      :per-page="config.per_page"
                      @change="gotoPage"
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </b-col>
    </b-row>

    <!-- Modal Advance Search -->
    <b-modal
      id="modal-form-redeem"
      ref="modal-form-redeem"
      centered
      title="Claim Poin"
      hide-footer
    >
      <validation-observer ref="formKFSRedeem">
        <form
          @submit.prevent="save"
        >
          <div class="animated fadeIn">
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="5">
                <label
                  class="h5"
                  for="date"
                >Tanggal</label>
              </b-col>
              <b-col sm="7">
                <validation-provider
                  #default="{ errors }"
                  name="Tanggal"
                  rules="required"
                >
                  <b-form-datepicker
                    id="date"
                    v-model="models.date"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="5">
                <label
                  class="h5"
                  for="product"
                >Produk</label>
              </b-col>
              <b-col sm="7">
                <validation-provider
                  #default="{ errors }"
                  name="Produk"
                  rules="required"
                >
                  <b-form-input
                    id="product"
                    v-model="models.reward"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="5">
                <label
                  class="h5"
                  for="poin"
                >Poin</label>
              </b-col>
              <b-col sm="7">
                <validation-provider
                  #default="{ errors }"
                  name="Poin"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="poin"
                    v-model="models.poin"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-md-end justify-content-around">

              <b-button
                type="button"
                variant="outline-secondary"
                class="btn-min-width rounded"
                @click="$bvModal.hide('modal-form-redeem')"
              >
                Batal
              </b-button>
              <b-button
                type="submit"
                variant="primary"
                class="btn-min-width rounded ml-md-1 ml-0"
              >
                Simpan
              </b-button>
            </div>
          </div>
        </form>
      </validation-observer>
    </b-modal>

    <!-- Modal Claim Poin -->
    <b-modal
      id="modal-claim-poin"
      ref="modal-claim-poin"
      centered
      title="Claim Poin"
      hide-footer
    >
      <validation-observer ref="formKFSRedeemPoin">
        <form
          @submit.prevent="savePoin"
        >
          <div class="animated fadeIn">
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="5">
                <label
                  class="h5"
                  for="date-poin"
                >Tanggal</label>
              </b-col>
              <b-col sm="7">
                <validation-provider
                  #default="{ errors }"
                  name="Tanggal"
                  rules="required"
                >
                  <b-form-datepicker
                    id="date-poin"
                    v-model="poin.expiredPoin"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :min="moment().format('YYYY-MM-DD')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="5">
                <label
                  class="h5"
                  for="initial-poin"
                >Setup Poin</label>
              </b-col>
              <b-col sm="7">
                <validation-provider
                  #default="{ errors }"
                  name="Poin"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="initial-poin"
                    v-model="poin.initialPoin"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-md-end justify-content-around">

              <b-button
                type="button"
                variant="outline-secondary"
                class="btn-min-width rounded"
                @click="$bvModal.hide('modal-claim-poin')"
              >
                Batal
              </b-button>
              <b-button
                type="submit"
                variant="primary"
                class="btn-min-width rounded ml-md-1 ml-0"
              >
                Simpan
              </b-button>
            </div>
          </div>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol, BPagination, BFormSelect, BButton, BModal, BFormDatepicker, BFormTextarea, BFormInput,
} from 'bootstrap-vue'
// import table from '@/utils/table.js'
import api from '@/utils/api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, numeric,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { exportExcel } from '@/utils/helpers'

export default {
  name: 'ListKFSRedeem',
  components: {
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    BModal,
    BFormDatepicker,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: [
    'claim-poin',
  ],
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: api.kfsRedeem,
        rows: [],
        additional_params: {
          kfsId: this.$route.params.id,
        },
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Tanggal',
          value: 'date',
          align: 'text-left',
          width: '35%',
        }, {
          title: 'Produk',
          value: 'reward',
          align: 'text-left',
          width: '25%',
        }, {
          title: 'Claim Poin',
          value: 'poin',
          align: 'text-left',
          width: '25%',
        }, {
          title: 'Aksi',
          value: '',
          align: 'text-left',
          width: '15%',
        }],
      },
      vars: {
        form: {
          modalTitle: '',
        },
        expiredPoin: 0,
        initialPoin: 0,
        enableSetDate: true,
      },

      poin: {
        expiredPoin: null,
        initialPoin: null,
      },

      required,
      numeric,
    }
  },
  computed: {
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
    enableSetDate() {
      return this.vars.enableSetDate
    },
  },
  watch: {
    perPage() {
      this.config.page = 1
      this.get()
    },
  },
  created() {
    const _ = this
    _.get()
  },
  methods: {
    get() {
      this.$table.get(this.config).then(res => {
        const { expiredPoin, enableSetDate, totalPoint } = res.data.data
        this.vars.expiredPoin = expiredPoin
        this.vars.totalPoint = totalPoint
        this.vars.enableSetDate = enableSetDate
      })
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    destroy(id) {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`${api.kfsRedeem}/${id}`)
            .then(res => {
              this.$refs.response.setResponse(res.data.message, 'success')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },
    save() {
      this.$refs.formKFSRedeem.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          if (this.vars.form.modalTitle === 'Tambah') {
            request = this.$axios.post(api.kfsRedeem, this.models)
          } else {
            message = 'update'
            delete this.models.kfsId
            request = this.$axios.put(`${api.kfsRedeem}/${this.vars.idRedeem}`, this.models)
          }
          request
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} KFS Redeem`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('modal-form-redeem')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
    savePoin() {
      this.$refs.formKFSRedeemPoin.validate().then(async success => {
        if (success) {
          this.$axios.put(`${api.kfs}/${this.$route.params.id}/expired-poin`, this.poin)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success setup poin',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('modal-claim-poin')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
    openEmployeeModal(state, id) {
      this.vars.form.modalTitle = state
      if (!id) {
        this.models = {
          kfsId: this.$route.params.id,
          date: '',
          reward: '',
          poin: '',
        }
        this.$bvModal.show('modal-form-redeem')
      } else {
        this.$axios.get(`${api.kfsRedeem}/${id}`)
          .then(res => {
            const { data } = res.data
            this.models = {
              kfsId: this.$route.params.id,
              date: data.date,
              reward: data.reward,
              poin: data.poin,
            }
            this.vars.idRedeem = id
            this.$bvModal.show('modal-form-redeem')
          })
      }
    },
    exportExcel() {
      exportExcel('List KFS Redeem', `${api.kfsRedeem}/export?kfsId=${this.$route.params.id}`)
    },
  },
}
</script>
