<template>
  <div class="animated fadeIn">
    <b-row>
      <ResponseAlert ref="response" />
      <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
        <div class="col-md-4 col-12 px-0 order-1 order-md-0">
          <div class="input-group">
            <input
              v-model="config.search"
              type="text"
              placeholder="Cari nama produk atau kata kunci"
              class="form-control form-control-sm"
              @keyup.enter="config.page = 1; get()"
            >
            <div class="input-group-append">
              <div
                class="input-group-text"
                @click="config.page = 1; get()"
              >
                <feather-icon icon="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div class="order-0 order-md-1 mb-1 mb-md-0 full-width-responsive">
          <b-button
            variant="secondary"
            class="btn-min-width rounded full-width-responsive mr-1 mb-1 mb-md-0"
            @click="$bvModal.show('modal-import-product')"
          >
            <feather-icon
              icon="UploadCloudIcon"
            />
            Import Data
          </b-button>
          <b-button
            v-if="hasPermission('export/product/kfs-management')"
            variant="secondary"
            class="btn-min-width rounded full-width-responsive"
            @click="exportExcel"
          >
            <feather-icon
              icon="DownloadIcon"
            />
            Export Data
          </b-button>
        </div>
      </div>
      <b-col sm="12">
        <div class="table-responsive">
          <table class="table b-table">
            <thead>
              <tr>
                <th
                  v-for="(row, key) in config.headers"
                  :key="key"
                  :width="row.width"
                  :class="row.align"
                >
                  <a
                    v-if="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                    @click="sort(row.value)"
                  >
                    {{ row.title }}
                    <i
                      :id="row.value"
                      class="fa fa-sort"
                    />
                  </a>
                  <a
                    v-else
                    :id="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                  >
                    {{ row.title }}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="config.total_data">
                <tr
                  v-for="(row, key) in config.rows"
                  :key="key"
                >
                  <td>{{ row.productName }}</td>
                  <td>{{ row.code }}</td>
                  <td>{{ row.productType }}</td>
                  <td>{{ row.productCategory }}</td>
                  <td>Rp. {{ parseFloat(row.price).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}</td>
                  <td>{{ row.stock }}</td>

                </tr>
              </template>
              <tr v-if="!config.total_data">
                <td
                  :colspan="config.headers.length"
                  align="center"
                >
                  No data available.
                </td>
              </tr>
            </tbody>
            <tfoot v-if="config.total_data">
              <tr>
                <td
                  :colspan="config.headers.length"
                  class="p-0"
                >
                  <div class="d-flex justify-content-between mt-2">
                    <b-form-select
                      v-model="config.per_page"
                      :options="perPageOptions"
                      class="w-auto"
                    />
                    <b-pagination
                      v-model="config.page"
                      :total-rows="config.total_data"
                      :per-page="config.per_page"
                      @change="gotoPage"
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </b-col>
    </b-row>

    <!-- Modal Advance Search -->
    <b-modal
      id="modal-form-product"
      ref="modal-form-product"
      centered
      title="Edit Produk"
      hide-footer
    >
      <validation-observer ref="formKFSProduct">
        <form
          @submit.prevent="save"
        >
          <div class="animated fadeIn">
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="5">
                <label
                  class="h5"
                >Nama Produk</label>
              </b-col>
              <b-col sm="7">
                <b-form-input
                  class="w-100"
                  :value="models.productName"
                  disabled
                />
              </b-col>
            </b-row>
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="5">
                <label
                  class="h5"
                  for="status"
                >Jenis Persediaan</label>
              </b-col>
              <b-col sm="7">
                <b-form-input
                  class="w-100"
                  disabled
                  :value="models.productType"
                />
              </b-col>
            </b-row>
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="5">
                <label
                  class="h5"
                  for="status"
                >Satuan</label>
              </b-col>
              <b-col sm="7">
                <b-form-input
                  class="w-100"
                  disabled
                />
              </b-col>
            </b-row>
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="5">
                <label
                  class="h5"
                  for="status"
                >Kategori</label>
              </b-col>
              <b-col sm="7">
                <b-form-input
                  class="w-100"
                  :value="models.productCategory"
                  disabled
                />
              </b-col>
            </b-row>
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="5">
                <label
                  class="h5"
                  for="status"
                >Harga</label>
              </b-col>
              <b-col sm="7">
                <b-form-input
                  class="w-100"
                  :value="`Rp. ${parseFloat(models.price).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.')}`"
                  disabled
                />
              </b-col>
            </b-row>
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="5">
                <label
                  class="h5"
                  for="stock"
                >Stok</label>
              </b-col>
              <b-col sm="7">
                <validation-provider
                  #default="{ errors }"
                  name="Stok"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="stock"
                    v-model="models.stock"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-md-end justify-content-around">

              <b-button
                type="button"
                variant="outline-secondary"
                class="btn-min-width rounded"
                @click="$bvModal.hide('modal-form-treatment')"
              >
                Batal
              </b-button>
              <b-button
                type="submit"
                variant="primary"
                class="btn-min-width rounded ml-md-1 ml-0"
              >
                Simpan
              </b-button>
            </div>
          </div>
        </form>
      </validation-observer>
    </b-modal>

    <!-- Modal Import -->
    <b-modal
      id="modal-import-product"
      ref="modal-import-product"
      centered
      hide-footer
    >
      <div
        class="py-3 d-flex justify-content-center align-items-center"
        @dragover.prevent
        @drop.prevent="event => importExcel(event, 'drag')"
      >
        <label
          for="file-input"
          style="cursor: pointer;"
        >
          <input
            id="file-input"
            type="file"
            accept=".xlsx, .xls, .csv"
            hidden
            @change="event => importExcel(event)"
          >
          <div class="text-center">
            <feather-icon
              icon="UploadCloudIcon"
              size="100"
            />
          </div>
          <span class="d-block">
            Upload File (.xlsx)
          </span>
        </label>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BPagination, BFormSelect, BButton, BModal, BFormInput,
} from 'bootstrap-vue'
// import table from '@/utils/table.js'
import api from '@/utils/api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { exportExcel } from '@/utils/helpers'

export default {
  name: 'ListKFSProduct',
  components: {
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: api.kfsInventory,
        rows: [],
        additional_params: {
          kfsId: this.$route.params.id,
        },
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Nama Produk',
          value: 'productName',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Kode Produk',
          value: '',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Jenis Produk',
          value: 'productType',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Kategori',
          value: 'productCategory',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Harga',
          value: 'price',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Stok',
          value: 'stock',
          align: 'text-left',
          width: '10%',
        }],

      },
      models: {
        productName: '',
        productType: '',
        productCategory: '',
        price: '',
        stock: '',
      },

    }
  },
  computed: {
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
  },
  watch: {
    perPage() {
      this.config.page = 1
      this.get()
    },
  },
  created() {
    const _ = this
    _.get()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    destroy(id) {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`${api.point}/${id}`)
            .then(res => {
              this.$refs.response.setResponse(res.data.message, 'success')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },
    save() {
      this.$refs.formKFSProduct.validate().then(async success => {
        if (success) {
          this.$axios.put(`${api.kfsInventory}/${this.models.idProduct}`, { stock: this.models.stock })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success update KFS Product',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('modal-form-product')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
    openEmployeeModal(id) {
      this.$axios.get(`${api.kfsInventory}/${id}`)
        .then(res => {
          const { data } = res.data
          this.models = {
            productName: data.productName,
            productType: data.productType,
            productCategory: data.productCategory,
            price: data.price,
            stock: data.stock,
          }
          this.$bvModal.show('modal-form-product')
          this.models.idProduct = id
        })
    },
    exportExcel() {
      exportExcel('List Produk KFS', `${api.kfsInventory}/export?kfsId=${this.$route.params.id}`)
    },
    // eslint-disable-next-line consistent-return
    importExcel(event, type) {
      const fileSizeLimit = 1024 * 1024 * 2 // limit 2 MB

      const filesLength = type === 'drag' ? event.dataTransfer.files.length : event.target.files[0].length
      const file = type === 'drag' ? event.dataTransfer.files[0] : event.target.files[0]
      if (filesLength > 1) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Only accept single file',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type !== 'application/vnd.ms-excel' && file.type !== 'text/csv') {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Format file not supported',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
      if (file.size < fileSizeLimit) {
        const form = new FormData()
        form.append('file', file)
        form.append('kfsId', this.$route.params.id)
        this.$axios.post(`${api.kfsInventory}/import`, form)
          .then(res => {
            if (res.data.data.length) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed',
                  text: 'Invalid format, please check format guide.',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success import data',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })

              this.get()
              this.$bvModal.hide('modal-import-product')
            }
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed',
                text: 'Format column file not support, please check format guide.',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
        // eslint-disable-next-line no-param-reassign
        event.target.value = null
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Max file size is 2 MB',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
