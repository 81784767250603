<template>
  <div class="animated fadeIn">
    <b-row>
      <ResponseAlert ref="response" />
      <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
        <div class="col-md-4 col-12 px-0 order-1 order-md-0">
          <div class="input-group">
            <input
              v-model="config.search"
              type="text"
              placeholder="Cari nama customer atau kata kunci"
              class="form-control form-control-sm"
              @keyup.enter="config.page = 1; get()"
            >
            <div class="input-group-append">
              <div
                class="input-group-text"
                @click="config.page = 1; get()"
              >
                <feather-icon icon="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div class="order-0 order-md-1 mb-1 mb-md-0 text-center full-width-responsive">
          <b-button
            v-if="hasPermission('export/promo/kfs-management')"
            variant="secondary"
            class="btn-min-width rounded full-width-responsive"
            @click="exportExcel"
          >
            <feather-icon
              icon="DownloadIcon"
            />
            Export Data
          </b-button>
          <custom-button
            text="Buat Promo"
            :url="`/${config.uri}/${$route.params.id}/promo/add`"
            permission="add/promo/kfs-management"
            class-name="btn btn-outline-danger display-table-cell pull-right rounded ml-0 ml-md-1 order-0 order-md-1 mb-1 mb-md-0 btn-width-responsive"
          />
        </div>
      </div>
      <b-col sm="12">
        <div class="table-responsive">
          <table class="table b-table">
            <thead>
              <tr>
                <th
                  v-for="(row, key) in config.headers"
                  :key="key"
                  :width="row.width"
                  :class="row.align"
                >
                  <a
                    v-if="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                    @click="sort(row.value)"
                  >
                    {{ row.title }}
                    <i
                      :id="row.value"
                      class="fa fa-sort"
                    />
                  </a>
                  <a
                    v-else
                    :id="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                  >
                    {{ row.title }}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="config.total_data">
                <tr
                  v-for="(row, key) in config.rows"
                  :key="key"
                >
                  <td>{{ row.name }} </td>
                  <td>{{ row.voucherCode }}</td>
                  <td>{{ moment(row.createdAt).format('DD-MM-YYYY') }}</td>
                  <td>{{ moment(row.startDate).format('DD-MM-YYYY') }} - {{ moment(row.endDate).format('DD-MM-YYYY') }}</td>
                  <td>{{ row.usedCount }}</td>
                  <td class="text-capitalize">
                    {{ row.statusText }}
                  </td>

                  <td>
                    <div class="d-flex justify-content-center">
                      <button
                        v-if="row.requestStatus === 0 && !row.isCanceled"
                        title="Approve"
                        class="btn-action bg-transparent border-0 text-primary p-0 pl-1"
                        @click="vars.detailPromoId = row.id; $bvModal.show('modal-approvement-promo')"
                      >
                        <feather-icon icon="CheckCircleIcon" />
                      </button>
                      <custom-button
                        permission="edit/promo/kfs-management"
                        :url="`/${config.uri}/${$route.params.id}/promo/edit/${row.id}`"
                        title="Edit"
                        class-name="btn-action p-0 pl-1"
                      >
                        <feather-icon icon="EditIcon" />
                      </custom-button>
                      <custom-button
                        type="button"
                        permission="delete/promo/kfs-management"
                        title="Delete"
                        class-name="btn-action bg-transparent border-0 text-primary p-0 pl-1"
                        @click="destroy(row.id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </custom-button>
                    </div>
                  </td>
                </tr>
              </template>
              <tr v-if="!config.total_data">
                <td
                  :colspan="config.headers.length"
                  align="center"
                >
                  No data available.
                </td>
              </tr>
            </tbody>
            <tfoot v-if="config.total_data">
              <tr>
                <td
                  :colspan="config.headers.length"
                  class="p-0"
                >
                  <div class="d-flex justify-content-between mt-2">
                    <b-form-select
                      v-model="config.per_page"
                      :options="perPageOptions"
                      class="w-auto"
                    />
                    <b-pagination
                      v-model="config.page"
                      :total-rows="config.total_data"
                      :per-page="config.per_page"
                      @change="gotoPage"
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </b-col>
    </b-row>

    <!-- Modal Export -->
    <b-modal
      id="modal-export-invoice"
      ref="modal-export-invoice"
      centered
      title="Export Data"
      hide-footer
    >
      <form
        @submit.prevent="save"
      >
        <div class="animated fadeIn">
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="date"
              >Tanggal</label>
            </b-col>
            <b-col sm="7">
              <b-form-datepicker id="date" />
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="location"
              >Lokasi</label>
            </b-col>
            <b-col sm="7">
              <b-form-select
                id="location"
                class="w-100"
              />
            </b-col>
          </b-row>

          <div class="d-flex justify-content-center justify-content-md-end mt-2">

            <b-button
              type="button"
              variant="outline-secondary"
              class="btn-min-width rounded"
            >
              Export Data
            </b-button>

          </div>
        </div>
      </form>
    </b-modal>

    <!-- Modal Approvement -->
    <b-modal
      id="modal-approvement-promo"
      ref="modal-approvement-promo"
      centered
      hide-header
      hide-footer
    >
      <div class="py-3">
        <h5 class="text-center mb-2">
          Penyetujuan Pengajuan Promo
        </h5>
        <div class="d-flex justify-content-center">
          <b-button
            variant="primary"
            class="btn-min-width rounded full-width-responsive mx-md-1"
            @click="updateStatusPromo(1)"
          >
            Setujui
          </b-button>
          <b-button
            variant="secondary"
            class="btn-min-width rounded full-width-responsive mx-1"
            @click="updateStatusPromo(-1)"
          >
            Tolak
          </b-button>
          <b-button
            variant="warning"
            class="btn-min-width rounded full-width-responsive mx-md-1"
            @click="updateStatusPromo('cancel')"
          >
            Batalkan
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol, BPagination, BFormSelect, BButton, BModal, BFormDatepicker,
} from 'bootstrap-vue'
// import table from '@/utils/table.js'
import api from '@/utils/api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { exportExcel } from '@/utils/helpers'

export default {
  name: 'ListKFSPromo',
  components: {
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    BModal,
    BFormDatepicker,
  },
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: api.kfsPromotion,
        rows: [],
        additional_params: {
          kfsId: this.$route.params.id,
        },
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Nama Promo',
          value: 'name',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Kode Promo',
          value: 'voucherCode',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Tanggal Dibuat',
          value: 'createdAt',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Tanggal Berlaku',
          value: 'startDate',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Jumlah Promo Terpakai',
          value: '',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Status',
          value: '',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Aksi',
          value: '',
          align: 'text-center',
          width: '10%',
        }],
      },
      vars: {},
    }
  },
  computed: {
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
  },
  watch: {
    perPage() {
      this.config.page = 1
      this.get()
    },
  },
  created() {
    const _ = this
    _.get()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    destroy(id) {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`${api.kfsPromotion}/${id}`)
            .then(res => {
              this.$refs.response.setResponse(res.data.message, 'success')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },
    exportExcel() {
      exportExcel('List KFS Promo', `${api.kfsPromotion}/export?kfsId=${this.$route.params.id}`)
    },
    updateStatusPromo(status) {
      const models = {}

      if (status === 'cancel') {
        models.isCanceled = true
      } else {
        models.requestStatus = status
      }

      this.$axios.put(`kfs-promotion/${this.vars.detailPromoId}/status`, models)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success update status promo',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('modal-approvement-promo')
          this.get()
        })
    },
  },
}
</script>
