<template>
  <div class="animated fadeIn">
    <b-row>
      <ResponseAlert ref="response" />
      <div class="col-sm-12 mb-2 mb-lg-2 d-flex justify-content-between align-items-center flex-column flex-lg-row">
        <div class="col-lg-4 px-0 order-1 order-md-0 mt-2 mb-0 mb-md-2 mt-lg-0 mb-lg-0">
          <div class="input-group">
            <input
              v-model="config.search"
              type="text"
              placeholder="Cari nama treatment atau kata kunci"
              class="form-control form-control-sm"
              @keyup.enter="config.page = 1; get()"
            >
            <div class="input-group-append">
              <div
                class="input-group-text"
                @click="config.page = 1; get()"
              >
                <feather-icon icon="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div class="order-0 order-md-1 mb-0 text-center">
          <b-button
            v-if="hasPermission('export/treatment/kfs-management')"
            variant="secondary"
            class="btn-min-width rounded full-width-responsive mx-md-1 my-1 my-md-0"
            @click="exportExcel"
          >
            <feather-icon
              icon="DownloadIcon"
            />
            Export Data
          </b-button>
          <b-button
            v-if="hasPermission('add/treatment/kfs-management')"
            variant="outline-danger"
            class="btn-min-width rounded full-width-responsive"
            @click="openEmployeeModal('Tambah')"
          >
            Tambah Treatment
          </b-button>
        </div>
      </div>
      <b-col sm="12">
        <div class="table-responsive">
          <table class="table b-table">
            <thead>
              <tr>
                <th
                  v-for="(row, key) in config.headers"
                  :key="key"
                  :width="row.width"
                  :class="row.align"
                >
                  <a
                    v-if="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                    @click="sort(row.value)"
                  >
                    {{ row.title }}
                    <i
                      :id="row.value"
                      class="fa fa-sort"
                    />
                  </a>
                  <a
                    v-else
                    :id="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                  >
                    {{ row.title }}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="config.total_data">
                <tr
                  v-for="(row, key) in config.rows"
                  :key="key"
                >
                  <td>{{ row.name }}</td>
                  <td>Rp. {{ parseFloat(row.unitPrice).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}</td>

                  <td>{{ row.priceFormat }}</td>
                  <td>{{ row.requestedStatus === 0 ? 'Pengajuan' : row.requestedStatus === -1 ? 'Reject' : 'Active' }}</td>
                  <td>
                    <div class="d-flex justify-content-around">
                      <button
                        v-if="row.requestedStatus === 0"
                        title="Approval"
                        class="btn-action bg-transparent border-0 text-primary p-0 "
                        @click="vars.detailTreatmentId = row.id; $bvModal.show('modal-approvement-treatment')"
                      >
                        <feather-icon icon="CheckCircleIcon" />
                      </button>
                      <custom-button
                        type="button"
                        permission="edit/treatment/kfs-management"
                        title="Edit"
                        class-name="btn-action bg-transparent border-0 text-primary p-0 mx-1 mx-lg-0"
                        @click="openEmployeeModal('Edit', row.id)"
                      >
                        <feather-icon icon="EditIcon" />
                      </custom-button>
                      <custom-button
                        type="button"
                        permission="delete/treatment/kfs-management"
                        title="Delete"
                        class-name="btn-action bg-transparent border-0 text-primary p-0 "
                        @click="destroy(row.id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </custom-button>
                    </div>
                  </td>
                </tr>
              </template>
              <tr v-if="!config.total_data">
                <td
                  :colspan="config.headers.length"
                  align="center"
                >
                  No data available.
                </td>
              </tr>
            </tbody>
            <tfoot v-if="config.total_data">
              <tr>
                <td
                  :colspan="config.headers.length"
                  class="p-0"
                >
                  <div class="d-flex justify-content-between mt-2">
                    <b-form-select
                      v-model="config.per_page"
                      :options="perPageOptions"
                      class="w-auto"
                    />
                    <b-pagination
                      v-model="config.page"
                      :total-rows="config.total_data"
                      :per-page="config.per_page"
                      @change="gotoPage"
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </b-col>
    </b-row>

    <!-- Modal Form Treatment -->
    <b-modal
      id="modal-form-treatment"
      ref="modal-form-treatment"
      centered
      no-close-on-backdrop
      :title="`${modalFormTitle} Treatment`"
      hide-footer
    >
      <validation-observer ref="formTreatments">
        <form
          @submit.prevent="save"
        >
          <div class="animated fadeIn">
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="4">
                <label
                  class="h5"
                  for="treatment"
                >Tipe</label>
              </b-col>
              <b-col sm="8">
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="models.type"
                    :options="masters.types"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="typeForm === 2"
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="4">
                <label
                  class="h5"
                  for="nameTreatment"
                >Nama treatment</label>
              </b-col>
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="Nama"
                  rules="required"
                >
                  <b-form-input
                    id="nameTreatment"
                    v-model="models.name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="4">
                <label
                  class="h5"
                  for="treatment"
                >Pilih Treatment</label>
              </b-col>
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="Treatment"
                  rules="required"
                >
                  <v-select
                    id="tag"
                    v-model="vars.treatments"
                    :close-on-select="false"
                    :clearable="false"
                    label="name"
                    placeholder="- Pilih Treatment -"
                    :state="errors.length > 0 ? false:null"
                    :options="masters.treatments"
                    multiple
                    @search="debounceSearch"
                    @option:selected="handleSelected"
                  >
                    <template #no-options="{ search, searching, loading }">
                      Tidak ditemukan hasil pencarian
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="4">
                <label
                  class="h5"
                  for="hargadasar"
                >Harga Dasar</label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                  id="hargadasar"
                  :value="basePriceFormatted"
                  disabled
                />
              </b-col>
            </b-row>
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="4">
                <label
                  class="h5"
                  for="status"
                >Durasi</label>
              </b-col>
              <b-col sm="8">
                <b-row
                  align-v="baseline"
                >
                  <b-col
                    class="col-6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Jam"
                      rules="required|numeric"
                    >
                      <div class="d-flex align-items-center">
                        <b-form-input
                          v-model="models.durationHours"
                          type="number"
                          :disabled="typeForm === 1"
                          class="mr-1"
                          :state="errors.length > 0 ? false:null"
                        />

                        <span> Jam</span>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="col-6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Menit"
                      rules="required|numeric|max_value:59"
                    >
                      <div class="d-flex align-items-center">
                        <b-form-input
                          v-model="models.durationMinutes"
                          type="number"
                          :disabled="typeForm === 1"
                          class="mr-1"
                          :state="errors.length > 0 ? false:null"
                        />
                        <span>Menit</span>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
              class="mb-1"
              align-v="baseline"
            >
              <b-col sm="4">
                <label
                  class="h5"
                  for="hargajual"
                >Harga Jual</label>
              </b-col>
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="Harga jual"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="hargajual"
                    v-model="models.price"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-md-end justify-content-around">

              <b-button
                type="button"
                variant="outline-secondary"
                class="btn-min-width rounded"
                @click="$bvModal.hide('modal-form-treatment')"
              >
                Batal
              </b-button>
              <b-button
                type="submit"
                variant="primary"
                class="btn-min-width rounded ml-md-1 ml-0"
              >
                Simpan
              </b-button>
            </div>
          </div>
        </form>
      </validation-observer>
    </b-modal>

    <!-- Modal Approvement -->
    <b-modal
      id="modal-approvement-treatment"
      ref="modal-approvement-treatment"
      centered
      hide-header
      hide-footer
    >
      <div class="py-3">
        <h5 class="text-center mb-2">
          Penyetujuan Pengajuan Treatment
        </h5>
        <div class="d-flex justify-content-center">
          <b-button
            variant="primary"
            class="btn-min-width rounded full-width-responsive mx-1"
            @click="updateStatusTreatment(1)"
          >
            Setujui
          </b-button>
          <b-button
            variant="secondary"
            class="btn-min-width rounded full-width-responsive mx-1"
            @click="updateStatusTreatment(-1)"
          >
            Tolak
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>

</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric, maxValue } from '@validations'
import {
  BRow, BCol, BPagination, BFormSelect, BButton, BModal, BFormInput, BFormGroup, BFormRadioGroup,
} from 'bootstrap-vue'
import api from '@/utils/api'
import { exportExcel } from '@/utils/helpers'

export default {
  name: 'ListKFSTreatment',
  components: {
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    BModal,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: api.treatments,
        rows: [],
        additional_params: {
          kfsId: this.$route.params.id,
        },
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Treatment',
          value: 'detail.name',
          align: 'text-left',
          width: '30%',
        }, {
          title: 'Harga Dasar',
          value: 'detail.unitPrice',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Harga Jual',
          value: 'price',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Status',
          value: '',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Aksi',
          value: '',
          align: 'text-center',
          width: '15%',
        }],
      },
      models: {
        type: 1,
        name: '',
        kfsId: this.$route.params.id,
        treatments: [],
        price: null,
        durationHours: null,
        durationMinutes: null,
      },
      masters: {
        treatments: [],
        types: [
          { text: 'Single', value: 1 },
          { text: 'Bundle', value: 2 },
        ],
      },
      vars: {
        form: {
          modalTitle: '',
        },
        unitPrice: '',
        treatments: [],
      },
      required,
      numeric,
      maxValue,
    }
  },
  computed: {
    modalFormTitle() {
      return this.vars.form.modalTitle
    },
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
    basePrice() {
      return this.vars.treatments.map(treatment => Number(treatment.unitPrice)).reduce((a, b) => a + b, 0)
    },
    basePriceFormatted() {
      return `Rp. ${parseFloat(this.basePrice).toLocaleString().replace(/[.,]/g, m => (m === '.' ? ',' : '.'))}`
    },
    treatments() {
      return this.vars.treatments
    },
    totalDurationMinutes() {
      return Number((this.vars.treatments.map(treatment => Number(treatment.durationHours)).reduce((a, b) => a + b, 0) * 60) + (this.vars.treatments.map(treatment => Number(treatment.durationMinutes)).reduce((a, b) => a + b, 0)))
    },
    typeForm() {
      return this.models.type
    },
  },
  watch: {
    typeForm(newVal) {
      if (newVal === 1) {
        if (this.vars.treatments.length) {
          this.vars.treatments.splice(1)
          return this.vars.treatments
        }
        this.vars.treatments = []
      }
      return this.vars.treatments
    },
    perPage() {
      this.config.page = 1
      this.get()
    },
    treatments() {
      if (this.treatments.length) {
        this.models.durationMinutes = this.totalDurationMinutes % 60
        this.models.durationHours = Math.floor(this.totalDurationMinutes / 60)
      }
    },
  },
  created() {
    const _ = this
    _.get()
    _.getOptions()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    debounceSearch(value) {
      if (value !== '') {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          const response = await this.handleSearchTag(value)
          this.masters.treatments = [...response]
        }, 500)
      }
    },
    handleSelected() {
      if (this.typeForm === 1 && this.vars.treatments.length > 1) {
        // change type to bundle
        this.models.type = 2
      }
    },
    getOptions() {
      this.$axios.get(api.accuTreatment).then(res => {
        this.masters.treatments = [...res.data.data.rows]
      })
    },
    async handleSearchTag(value) {
      const res = await this.$axios.get(`${api.list_treatments}?search=${value}`)
      return res.data.data
    },
    save() {
      this.$refs.formTreatments.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          if (this.vars.treatments.length) {
            this.models.treatments = this.vars.treatments.map(treatment => treatment.id)
          }

          if (this.typeForm === 1) {
            delete this.models.name
          }

          if (this.vars.form.modalTitle === 'Tambah') {
            request = this.$axios.post(api.treatments, this.models)
          } else {
            message = 'update'
            delete this.models.kfsId
            request = this.$axios.put(`${api.treatments}/${this.vars.idTreatment}`, this.models)
          }
          request
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} KFS Treatment`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('modal-form-treatment')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
    destroy(id) {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`${api.treatments}/${id}`)
            .then(res => {
              this.$refs.response.setResponse(res.data.message, 'success')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },
    openEmployeeModal(state, id) {
      this.vars.form.modalTitle = state
      if (state === 'Tambah') {
        this.models = {
          type: 1,
          name: '',
          kfsId: this.$route.params.id,
          treatments: [],
          price: null,
          durationHours: null,
          durationMinutes: null,
        }
        this.vars.treatments = []
        this.vars.unitPrice = ''
        this.$bvModal.show('modal-form-treatment')
      } else {
        this.$axios.get(`${api.treatments}/${id}`)
          .then(res => {
            const { data } = res.data
            const treatments = Array.isArray(data.collectionTreatment) ? data.collectionTreatment.map(treatment => treatment.id) : [data.collectionTreatment.id]

            this.models = {
              type: data.type,
              name: data.name,
              kfsId: this.$route.params.id,
              treatments,
              price: data.price,
              durationHours: data.durationHours,
              durationMinutes: data.durationMinutes,
            }

            this.vars.treatments = Array.isArray(data.collectionTreatment) ? data.collectionTreatment : [data.collectionTreatment]
            this.vars.unitPrice = data.unitPrice
            this.vars.idTreatment = id

            if (data.collectionTreatment.length > 1) {
              data.collectionTreatment.forEach(treatment => {
                if (!this.masters.treatments.map(treatmentMasters => treatmentMasters.id).includes(treatment.id)) {
                  this.masters.treatments.push(treatment)
                }
              })
            }
            this.$bvModal.show('modal-form-treatment')
          })
      }
    },
    updateStatusTreatment(status) {
      this.$axios.put(`treatments/${this.vars.detailTreatmentId}/status`, { status })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success update status treatment',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('modal-approvement-treatment')
          this.get()
        })
    },
    exportExcel() {
      exportExcel('List Treatment', `${api.treatments}/export?kfsId=${this.$route.params.id}`)
    },
  },

}
</script>
