<template>
  <div class="animated fadeIn">

    <b-card class="px-md-2">
      <h3 class="mb-2">
        {{ $route.meta.breadcrumb[1].text }}
      </h3>
      <b-tabs
        fill
        pills
      >
        <b-tab
          lazy
          :active="defaultActive === 'karyawan'"
          title="Karyawan"
        >
          <karyawan />
        </b-tab>
        <b-tab
          lazy
          :active="defaultActive === 'treatment'"
          title="Treatment"
        >
          <treatment />
        </b-tab>
        <b-tab
          lazy
          :active="defaultActive === 'product'"
          title="Produk"
        >
          <product />
        </b-tab>
        <b-tab
          :active="defaultActive === 'promo'"
          lazy
          title="Promo"
        >
          <promo />
        </b-tab>
        <b-tab
          lazy
          title="Claim Poin"
        >
          <claim-poin :claim-poin="claimPoin" />
        </b-tab>
        <b-tab
          :active="defaultActive === 'customer'"
          lazy
          title="Customer"
        >
          <customer />
        </b-tab>
        <b-tab
          lazy
          title="Old Transaction"
        >
          <old-transaction />
        </b-tab>
        <b-tab
          lazy
          title="Detail Info"
        >
          <detail :models="models" />
        </b-tab>
      </b-tabs>

      <div class="d-flex justify-content-center justify-content-md-end">
        <b-button
          type="button"
          variant="light"
          class="btn-min-width rounded mx-1"
          @click="$router.push({path: config.uri})"
        >
          Kembali
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab, BButton,
} from 'bootstrap-vue'
import api from '@/utils/api'
import {
  Karyawan, Product, Promo, ClaimPoin, Detail, Treatment, Customer, OldTransaction,
} from './index'

export default {
  name: 'DetailKFS',
  metaInfo: {
    title: 'Detail KFS',
  },
  components: {
    BCard,
    BTabs,
    BTab,
    BButton,
    Customer,
    Karyawan,
    Treatment,
    Product,
    Promo,
    ClaimPoin,
    Detail,
    OldTransaction,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      defaultActive: '',
      claimPoin: {
        expiredPoin: null,
      },
      models: {},
    }
  },
  mounted() {
    this.defaultActive = this.$route.params.defaultActive || 'karyawan'
    this.getById()
  },
  methods: {
    getById() {
      if (this.$route.params.id) {
        this.$axios.get(`${api.kfs}/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data
            this.models.address = data.address
            this.models.phone = data.phone
            this.models.joinedDate = data.createdAt
            this.models.banks = data.banks

            this.claimPoin.expiredPoin = data.expiredPoin
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },
  },
}
</script>

<style>

</style>
