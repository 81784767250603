<template>
  <div class="animated fadeIn">
    <b-row>
      <ResponseAlert ref="response" />
      <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
        <div class="col-md-4 col-12 px-0 order-1 order-md-0">
          <div class="input-group">
            <input
              v-model="config.search"
              type="text"
              placeholder="Cari nama customer atau kata kunci"
              class="form-control form-control-sm"
              @keyup.enter="config.page = 1; get()"
            >
            <div class="input-group-append">
              <div
                class="input-group-text"
                @click="config.page = 1; get()"
              >
                <feather-icon icon="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div class="order-0 order-md-1 mb-1 mb-md-0 full-width-responsive">
          <b-button
            variant="secondary"
            class="btn-min-width rounded full-width-responsive mr-1 mb-1 mb-md-0"
            @click="$bvModal.show('modal-import-product')"
          >
            <feather-icon
              icon="UploadCloudIcon"
            />
            Import Data
          </b-button>
          <a
            class="btn-min-width rounded full-width-responsive btn-primary btn"
            :href="config.templateUrl"
          >
            <feather-icon
              icon="DownloadIcon"
            />
            Download Template
          </a>
        </div>
      </div>
      <b-col sm="12">
        <div class="table-responsive">
          <table class="table b-table table-custom">
            <thead>
              <tr>
                <th
                  v-for="(row, key) in config.headers"
                  :key="key"
                  :width="row.width"
                  :class="row.align"
                >
                  <a
                    v-if="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                    @click="sort(row.value)"
                  >
                    {{ row.title }}
                    <i
                      :id="row.value"
                      class="fa fa-sort"
                    />
                  </a>
                  <a
                    v-else
                    :id="row.value"
                    class="sort dark-font"
                    href="javascript:void(0)"
                  >
                    {{ row.title }}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="config.total_data">
                <tr
                  v-for="(row, key) in config.rows"
                  :key="key"
                >
                  <td>{{ row.invoiceCode }}</td>
                  <td>{{ row.fullName }}</td>
                  <td>{{ row.status }}</td>
                  <td>{{ row.invoiceDate }}</td>
                  <td>{{ row.dueDate }}</td>
                  <td>{{ row.kfsName }}</td>
                  <td>{{ row.totalTips }}</td>
                  <td>{{ row.grossTotal }}</td>
                </tr>
              </template>
              <tr v-if="!config.total_data">
                <td
                  :colspan="config.headers.length"
                  align="center"
                >
                  No data available.
                </td>
              </tr>
            </tbody>
            <tfoot v-if="config.total_data">
              <tr>
                <td
                  :colspan="config.headers.length"
                  class="p-0"
                >
                  <div class="d-flex justify-content-between mt-2">
                    <b-form-select
                      v-model="config.per_page"
                      :options="perPageOptions"
                      class="w-auto"
                    />
                    <b-pagination
                      v-model="config.page"
                      :total-rows="config.total_data"
                      :per-page="config.per_page"
                      @change="gotoPage"
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </b-col>
    </b-row>

    <!-- Modal Import -->
    <b-modal
      id="modal-import-product"
      ref="modal-import-product"
      centered
      hide-footer
    >
      <div
        class="py-3 d-flex justify-content-center align-items-center"
        @dragover.prevent
        @drop.prevent="event => importExcel(event, 'drag')"
      >
        <label
          for="file-input"
          style="cursor: pointer;"
        >
          <input
            id="file-input"
            type="file"
            accept=".xlsx, .xls, .csv"
            hidden
            @change="event => importExcel(event)"
          >
          <div class="text-center">
            <feather-icon
              icon="UploadCloudIcon"
              size="100"
            />
          </div>
          <span class="d-block">
            Upload File (.xlsx)
          </span>
        </label>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol, BPagination, BFormSelect, BButton, BModal,
} from 'bootstrap-vue'
// import table from '@/utils/table.js'
import api from '@/utils/api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ListKFSProduct',
  components: {
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    BModal,
  },
  data() {
    return {
      config: {
        templateUrl: `${process.env.VUE_APP_API}/templates/format-import-old-transaction.xlsx`,
        uri: this.$route.meta.link,
        api: api.oldTransaction,
        rows: [],
        additional_params: {
          kfsId: this.$route.params.id,
        },
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Invoice',
          value: '',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Nama',
          value: '',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Status',
          value: '',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Invoice Date',
          value: '',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Due Date',
          value: '',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Location',
          value: '',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Total Tip',
          value: '',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Gross Total',
          value: '',
          align: 'text-left',
          width: '10%',
        },
        ],
      },
    }
  },
  computed: {
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
  },
  watch: {
    perPage() {
      this.config.page = 1
      this.get()
    },
  },
  created() {
    const _ = this
    _.get()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    // eslint-disable-next-line consistent-return
    importExcel(event, type) {
      const fileSizeLimit = 1024 * 1024 * 2 // limit 2 MB

      const filesLength = type === 'drag' ? event.dataTransfer.files.length : event.target.files[0].length
      const file = type === 'drag' ? event.dataTransfer.files[0] : event.target.files[0]
      if (filesLength > 1) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Only accept single file',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type !== 'application/vnd.ms-excel' && file.type !== 'text/csv') {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Format file not supported',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
      if (file.size < fileSizeLimit) {
        const form = new FormData()
        form.append('file', file)
        form.append('kfsId', this.$route.params.id)
        this.$axios.post(`${api.oldTransaction}/import`, form)
          .then(res => {
            if (res.data.data.length) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed',
                  text: 'Invalid format, please check format guide.',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success import data',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })

              this.get()
              this.$bvModal.hide('modal-import-product')
            }
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed',
                text: 'Format column file not support, please check format guide.',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
        // eslint-disable-next-line no-param-reassign
        event.target.value = null
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Max file size is 2 MB',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style scoped>
.table-custom td, .table-custom th {
  white-space: nowrap;
}
</style>
